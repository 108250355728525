import React, { createContext, useState, useContext } from "react";
import axiosInstance from "../../lib/axios/AxiosConfig";
import { useNavigate } from "react-router-dom";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState();
  //   const [companyData, setCompanyData] = useState();
  const [jobOffers, setJobOffers] = useState();
  //   const [companyProfile, setCompanyProfile] = useState();
  //   const [companyPhotos, setCompanyPhotos] = useState();
  //   const [visibleSideBar, setVisibleSideBar] = useState(
  //     window.innerWidth > 1380 ? true : false
  //   );

  const getAdminJobOffers = async () => {
    try {
      const response = await axiosInstance.get("/a_jobOffer/all");
      setJobOffers(response.data);
    } catch (error) {}
  };

  //   const getCompanyData = async () => {
  //     try {
  //       const response = await axiosInstance.get("/c_company/context");
  //       setCompanyData(response.data);
  //       getcompanyJobOffers();
  //     } catch (error) {
  //       console.log(error);
  //       if (error.response && error.response.status === 401) {
  //         await logout();
  //       }
  //     }
  //   };

  //   const getPhotos = async () => {
  //     try {
  //       const response = await axiosInstance.get("/c_company/all_photos");
  //       setCompanyPhotos(response.data);
  //     } catch (error) {
  //       if (error.response && error.response.status === 401) {
  //         await logout();
  //       }
  //     }
  //   };

  //   const getCompanyProfile = async () => {
  //     try {
  //       const response = await axiosInstance.get("/c_company");
  //       setCompanyProfile(response.data);
  //     } catch (error) {
  //       if (error.response && error.response.status === 401) {
  //         await logout();
  //       }
  //     }
  //   };

  const logout = async () => {
    try {
      await axiosInstance.post("/a_authentication/login");
      //   setCompanyData(null);
      //   setCompanyProfile(null);
      //   jobOffers(null);
      navigate("/adminportal");
    } catch (error) {}
  };

  //   const patchCompanyData = async (Data) => {
  //     try {
  //       const response = await axiosInstance.patch("/c_company", Data);
  //       console.log(response.data);
  //       await getCompanyData();
  //     } catch (error) {
  //       console.error("Error updating company data:", error);
  //       throw new Error("Failed to update company data.");
  //     }
  //   };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <AdminContext.Provider
      value={{
        // getCompanyProfile,
        // companyProfile,
        // companyData,
        // getcompanyJobOffers,
        // jobOffers,
        // setCompanyData,
        // patchCompanyData,
        // visibleSideBar,
        // setVisibleSideBar,
        // getCompanyData,
        logout,
        getAdminJobOffers,
        // getPhotos,
        // companyPhotos,
        // setCompanyPhotos,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
