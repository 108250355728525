import React, { useEffect } from "react";
import styles from "./Modal.module.css";

const Modal = ({ active, setActive, children, width, closable = true }) => {
  useEffect(() => {
    if (active) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    // Cleanup effect
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [active]);
  //test

  return (
    <div
      className={active ? `${styles.modal} ${styles.active}` : styles.modal}
      onClick={() => closable && setActive(false)}
    >
      <div
        className={styles.content}
        style={{ width }}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
