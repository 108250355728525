import React, { useEffect, useState } from "react";
import io from "socket.io-client";

function Test() {
  //   const socket = io.connect("https://dev.globemee.com");

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketInstance = io("https://dev.globemee.com", {
      extraHeaders: { Role: "talent" },
      withCredentials: true,
    });

    // socketInstance.on("connect", () => {
    //   console.log("WebSocket connected");
    // });

    // socketInstance.on("message", (message) => {
    //   console.log("Received message:", message);
    // });

    // // Сохраняем инстанс сокета в состоянии
    // setSocket(socketInstance);

    // // Очистка соединения при размонтировании компонента
    // return () => {
    //   socketInstance.disconnect();
    // };
  }, []);

  //   let socket = null;

  //   function login() {
  //     fetch("https://dev.globemee.com/c_authentication/login", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         userEmail: "savinesav@gmail.com",
  //         password: "savinesav@gmail.com",
  //       }),
  //       credentials: "include", // This is important to include the session cookie
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.error) {
  //           alert("Login failed: " + data.error);
  //         } else {
  //           console.log("Login successful!");
  //           // Now that we're logged in, establish the WebSocket connection
  //           initializeSocket();
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }

  //   const initializeSocket = () => {
  //     socket = io("https://dev.globemee.com", {
  //       extraHeaders: { Role: "talent" },
  //       withCredentials: true,
  //     });

  //     // socket.on("connect", function () {
  //     //   console.log("Connected to server");
  //     // });

  //     // socket.on("disconnect", function () {
  //     //   console.log("Disconnected from server");
  //     // });

  //     // socket.on("status", function (data) {
  //     //   //   addMessage(`Server: ${data.msg}`);
  //     // });

  //     // socket.on("receive_message", function (data) {
  //     //   //   addMessage(`User ${data.senderId}: ${data.message}`);
  //     // });
  //   };

  return (
    <>
      {/* <button onClick={() => login()}>login</button>
      <button onClick={() => initializeSocket()}>click</button> */}
    </>
  );
}

export default Test;
